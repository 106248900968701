<template>
<div>
  <div class="row">
    <div class="col-md-12">
        <div class="card card-outline card-primary">
              <div class="card-header">
                <h2 class="card-title">Bestellverlauf</h2>
                <div class="card-tools">
                  <div class="row">
                    <div class="col-md-5">
                      <div class="form-group row">
                        <label class="col-md-3 col-form-label">Von:</label>
                        <div class="col-md-9">
                          <input type="date" class="form-control form-control-sm" v-model="start_date" />
                        </div>   
                      </div>
                    </div>

                    <div class="col-md-5">
                      <div class="form-group row">
                        <label class="col-md-3 col-form-label">Bis:</label>
                        <div class="col-md-9">
                          <input type="date" class="form-control form-control-sm" v-model="stop_date" />
                        </div>  
                      </div>
                    </div>

                    <div class="col-md-2">
                      <button class="btn btn-primary btn-sm" @click="loadSales"><i class="fas fa-sync"></i></button>
                    </div>

                    
                  </div>
                  
                </div>
              </div>

              <div class="card-body">
                    <Chart v-if="loaded == true" :chartData="chartdata" style="height: 500px; width: 100%;"/>        
              </div>
        </div>
        
      </div>
    </div>
  
  <div class="row">
        
        <div class="col-md-12">
            <div class="card card-outline card-primary">
              
              <div class="card-header">
                <h2 class="card-title">Bestellungen</h2>  
                <div class="card-tools">
                  <div class="row">
                    <div class="col-md-6">
                      <strong>Gesamt Stück: {{totalQuantity }} Stk.</strong>
                    </div>
                    <div class="col-md-6">
                      <strong>Gesamt: {{totalAmount | toCurrency}}</strong>
                    </div>
                  </div>
                </div>               
              </div>
              

              <div class="card-body">
                <table class="table table-striped table-sm table-bordered" v-if="sales.length > 0">
                  <thead class="thead-light">
                    <th>Datum</th>
                    <th>Umsatz</th>
                    <th>Verkäufe</th>
                    <th>Aktionen</th>
                  </thead>

                  <tbody>
                    <tr v-for="sale in sales" :key="sale.id">
                      <td>{{ sale.created_at | formatDateTime }}</td>
                      <td>{{ sale.price * sale.quantity | toCurrency }}</td>
                      <td>{{ sale.quantity }}</td>
                      <td>
                        <router-link :to="{name: 'orders.details', 'params': { 'id': sale.order_id}}" class="btn btn-primary btn-sm">
                            <i class="fas fa-clipboard"></i>
                        </router-link>
                        <!-- <button class="mr-1 btn btn-danger btn-sm"><i class="fas fa-trash"></i></button>        -->
                        <!-- <button class="mr-1 btn btn-info btn-sm" @click="sendMail(document)"><i class="fas fa-envelope"></i></button>
                        <button v-if="document.document.key == 'picklist'" class="btn btn-success btn-sm" @click="updateDocument(document.id)"><i class="fas fa-redo"></i></button> -->
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div v-else>
                  <h6>Für diesen Artikel liegen noch keine Bestellungen vor.</h6>
                </div>
              </div>
                
              

              <div class="card-footer">
                
              </div>

            </div>
        </div>
  </div>
</div>
</template>

<script>
import moment from 'moment'
import Chart from './Chart';

export default {
  name: "CustomersOrderData",

  props: [
    'article'
  ],

  components: {
      Chart,
  },

  data(){
    return{
      loaded: false,
      sales: [],
      start_date: '',
      stop_date: '',
      chartdata: {
        'labels': '',
        'data': '',
      },
      options: '',
    }
  },

  computed: {

    totalAmount: function() {
      return this.sales.reduce((a, b) => +a + (+b.price * +b.quantity), 0);
    },

    totalQuantity: function() {
      return this.sales.reduce((a, b) => +a + +b.quantity, 0);
    }

    
  },

  methods:{
    loadSales(){
      this.loaded = false;
        this.axios
        .post("/articles/getSalesChart", {
          article_id: this.article.id,
          start_date: this.start_date,
          stop_date: this.stop_date,
        })
        .then((response) => {
            this.sales = response.data.data.sales;
            this.chartdata.labels = response.data.data.labels;
            this.chartdata.data = response.data.data.amount;
            this.loaded = true;
        })
        .catch(() => {
            this.$swal({
                icon: "error",
                title: "Oops...",
                text: "Da ist etwas schief gelaufen",
            });
            this.loaded = false;
        });
    }
  },

  mounted(){
    this.start_date = moment().subtract(1, 'year').format('Y-MM-DD');
    this.stop_date =  moment().format('Y-MM-DD');
    this.loadSales();
  }

}
</script>

<style>

</style>